import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';
import ResultsModal from '../modals/results-modal';
import startNewGame from '../game-management/start-new-game';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

class EndGameButton extends React.Component {
  constructor() {
    super();

    this.state = {
      gameOverIsDisplayed: false,
    };
  }

  getGameOverSetter(gameOverIsDisplayed) {
    return () => {
      if (!gameOverIsDisplayed) {
        this.props.setGame();
      } else {
        gtag('event', 'end-game-click');
      }

      this.setState({ gameOverIsDisplayed });
    };
  }

  render() {
    const { game } = this.props;
    const { gameOverIsDisplayed } = this.state;

    return gameOverIsDisplayed ? (
      <ResultsModal game={game} message="Game Over" onClick={this.getGameOverSetter(false)} />
    ) : (
      <Button onClick={this.getGameOverSetter(true)}>End Game</Button>
    );
  }
}

export default connect(
  'game,gameMode',
  actions
)(EndGameButton);

import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';
import constants from '../../constants';

class GameMode extends React.Component {
  get gameMode() {
    const { gameMode, gameMetadata } = this.props;

    return constants.GAME_MODES_TEXT[gameMetadata.gameMode || gameMode];
  }

  render() {
    return <h4>{this.gameMode}</h4>;
  }
}

export default connect(
  'gameMode,gameMetadata',
  actions
)(GameMode);

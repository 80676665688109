export default function drawCard(ctx, card, { x, y, width, height }) {
  const center = { x: (width + 2 * x) / 2, y: (height + 2 * y) / 2 };
  const cardDetails = getCardDetails(card);

  drawCardBorder(ctx, { x, y, width, height });

  drawShapes(ctx, { ...center, width, height }, cardDetails);
}

function drawCardBorder(ctx, { x, y, width, height }) {
  const widthToHeightRatio = width / height;
  const xPadding = (widthToHeightRatio * width) / 5;
  const yPadding = height / 10;

  ctx.strokeStyle = '#000000';

  squareRect(ctx, {
    x: x + xPadding,
    y: y + yPadding,
    width: width - 2 * xPadding,
    height: height - 2 * yPadding,
  });
}

function squareRect(ctx, { x, y, width, height }, radius = 5) {
  if (width > 75) {
    ctx.beginPath();
    ctx.fillStyle = '#FFFFFF';
    ctx.strokeStyle = '#000000';
    ctx.lineWidth = 3;
    ctx.rect(x + 1, y + 1, width, height);
    ctx.fill();
    ctx.stroke();
    ctx.closePath();
  }

  ctx.beginPath();
  ctx.fillStyle = '#FFFFFF';
  ctx.strokeStyle = '#000000';
  ctx.lineWidth = 1;
  ctx.rect(x, y, width, height);
  ctx.fill();
  ctx.stroke();
  ctx.closePath();
}

function roundRect(ctx, { x, y, width, height }, radius = 5) {
  // http://js-bits.blogspot.com/2010/07/canvas-rounded-corner-rectangles.html
  ctx.beginPath();

  const inset = 2;
  ctx.fillStyle = '#FFFFFF';
  ctx.rect(x + inset, y + inset, width - 2 * inset, height - 2 * inset);
  ctx.fill();

  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);

  ctx.closePath();

  ctx.stroke();
}

// Card Details
function getCardDetails(card) {
  return {
    color: getColor(card),
    shape: getShape(card),
    fill: getFill(card),
    count: getCount(card),
  };
}

function getColor(card) {
  // yellow, red, dark gray
  const colors = ['#E7E247', '#881600', '#1E2019'];

  return colors[card.d1 - 1];
}

function getShape(card) {
  const shapes = ['circle', 'triangle', 'rectangle'];

  return shapes[card.d2 - 1];
}

function getFill(card) {
  const fills = ['none', 'solid', 'pattern', 'gradient'];

  return fills[card.d3 - 1];
}

function getCount(card) {
  return card.d4;
}

// Drawing
function drawShapes(ctx, position, { color, shape, fill, count }) {
  const { x, y, height } = position;

  if (count == 1) {
    drawShape(ctx, position, { color, shape });

    setFill(ctx, { color, fill, shape }, position);
  } else if (count == 2) {
    const offset = height / 6;
    const firstPosition = { ...position, x, y: y - offset };
    const secondPosition = { ...position, x, y: y + offset };

    drawShape(ctx, firstPosition, { color, shape });
    setFill(ctx, { color, fill, shape }, firstPosition);

    drawShape(ctx, secondPosition, { color, shape });
    setFill(ctx, { color, fill, shape }, secondPosition);
  } else if (count == 3) {
    const offset = height / 5;
    const firstPosition = { ...position, x, y };
    const secondPosition = { ...position, x, y: y - offset };
    const thirdPosition = { ...position, x, y: y + offset };

    drawShape(ctx, firstPosition, { color, shape });
    setFill(ctx, { color, fill, shape }, firstPosition);

    drawShape(ctx, secondPosition, { color, shape });
    setFill(ctx, { color, fill, shape }, secondPosition);

    drawShape(ctx, thirdPosition, { color, shape });
    setFill(ctx, { color, fill, shape }, thirdPosition);
  }
}

function setFill(ctx, { color, fill, shape }, { x, y, width, height }) {
  if (fill == 'solid') {
    ctx.fillStyle = color;
    ctx.fill();
  } else if (fill == 'gradient') {
    const { radius, rectWidth, triangleWidth } = getShapeDimensions({ x, y, height, width, shape });
    const shapeWidth = 2 * radius || rectWidth || triangleWidth;
    const xOffset = shapeWidth / 2;
    const line = {
      start: { x: x - xOffset, y },
      end: { x: x + xOffset, y },
    };
    const gradient = ctx.createLinearGradient(line.start.x, line.start.y, line.end.x, line.end.y);

    // drawLine(ctx, line);

    gradient.addColorStop(0, color);
    gradient.addColorStop(1, '#FFFFFF');

    ctx.fillStyle = gradient;

    ctx.fill();
  } else if (fill == 'pattern') {
    const assets = [...document.querySelectorAll('#assets img')];
    const lineImg = assets.find(img => img.className == `line-${color}`);

    /**
     * Safari can't use SVGs as patterns. Booooooo.
     *
     * Workaround: https://stackoverflow.com/questions/29793689/safari-createpattern-fails-with-svgs-suggestions-for-alternatives
     */
    const lineImgCanvas = document.createElement('canvas');
    const svgCtx = lineImgCanvas.getContext('2d');

    lineImgCanvas.width = lineImg.width;
    lineImgCanvas.height = lineImg.height;

    svgCtx.drawImage(lineImg, 0, 0, lineImg.width, lineImg.height);

    // End workaround

    ctx.fillStyle = '#ffffff';

    ctx.fill();

    ctx.fillStyle = ctx.createPattern(lineImgCanvas, 'repeat');

    ctx.fill();
  } else if (fill == 'none') {
    ctx.fillStyle = '#ffffff';

    ctx.fill();
  }
}

function drawShape(ctx, { x, y, height, width }, { color, shape }) {
  const {
    radius,
    rectWidth,
    rectHeight,
    triangleWidth,
    triangleHeight,
    startX,
    startY,
  } = getShapeDimensions({ x, y, height, width, shape });
  ctx.beginPath();

  setLineWidth(ctx);
  setColor(ctx, { color });

  if (shape == 'circle') {
    ctx.moveTo(x, y);
    ctx.arc(x, y, radius, 0, Math.PI * 2);
  } else if (shape == 'triangle') {
    const lineWidth = ctx.lineWidth;

    ctx.moveTo(startX + 0.25 * lineWidth, startY);
    ctx.lineTo(startX - 0.5 * triangleWidth, y + triangleHeight);
    ctx.lineTo(startX + 0.5 * triangleWidth, y + triangleHeight);
    ctx.lineTo(startX - 0.25 * lineWidth, startY);
  } else if (shape == 'rectangle') {
    ctx.rect(x - 0.5 * rectWidth, y - 0.5 * rectHeight, rectWidth, rectHeight);
  }

  ctx.stroke();
  ctx.closePath();
}

function getShapeDimensions({ x, y, height, width, shape }) {
  const dimensions = {
    radius: 0,
    triangleHeight: 0,
    triangleWidth: 0,
    rectWidth: 0,
    rectHeight: 0,
  };

  if (shape == 'circle') {
    dimensions.radius = height / 15;
  } else if (shape == 'triangle') {
    const widthToHeightRatio = width / height;
    const heightRatio = 12;
    const widthRatio = widthToHeightRatio * (0.5 * heightRatio);

    dimensions.triangleHeight = height / heightRatio;
    dimensions.triangleWidth = width / widthRatio;
    dimensions.startX = x;
    dimensions.startY = y - 0.5 * dimensions.triangleHeight;
  } else if (shape == 'rectangle') {
    const widthToHeightRatio = width / height;
    const xPadding = (widthToHeightRatio * width) / 5;
    const cardBorderWidth = width - 2 * xPadding;

    dimensions.rectWidth = Math.min(width / 3, 0.8 * cardBorderWidth);
    dimensions.rectHeight = height / 10;
  }

  return dimensions;
}

function setLineWidth(ctx) {
  ctx.lineWidth = 3;
}

function setColor(ctx, { color }) {
  ctx.strokeStyle = color;
}

function drawLine(ctx, { start: { x: startX, y: startY }, end: { x: endX, y: endY } }) {
  ctx.beginPath();

  ctx.moveTo(startX, startY);
  ctx.lineTo(endX, endY);

  ctx.stroke();

  ctx.closePath();
}

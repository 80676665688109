module.exports = function makeMatch(state, metadata = {}) {
  const strikes = [...state.strikes];

  strikes.push({ metadata });

  return {
    ...state,
    strikes,
    selectedIndexes: new Set(),
  };
};

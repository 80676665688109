const GAME_MODES = {
  EASY: 'easy',
  NORMAL: 'normal',
};

const GAME_MODES_TEXT = {
  [GAME_MODES.EASY]: 'Easy',
  [GAME_MODES.NORMAL]: 'Difficult',
};

const GAME_STATES = {
  PENDING: 'pending',
  PLAYING: 'playing',
  FINISHED: 'finished',
};

const GAME_STATES_TEXT = {
  [GAME_STATES.PENDING]: 'Not started',
  [GAME_STATES.PLAYING]: 'Playing',
  [GAME_STATES.FINISHED]: 'Finished',
};

const GAME_TYPES = {
  SOLO: 'solo',
  HEAD_TO_HEAD: 'head-to-head',
  TIME_TRIAL: 'time-trial',
};

const GAME_TYPES_TEXT = {
  [GAME_TYPES.SOLO]: 'Solo',
  [GAME_TYPES.HEAD_TO_HEAD]: 'Head to Head',
  [GAME_TYPES.TIME_TRIAL]: 'Time Trial',
};

module.exports = {
  GAME_MODES,
  GAME_MODES_TEXT,
  GAME_STATES,
  GAME_STATES_TEXT,
  GAME_TYPES,
  GAME_TYPES_TEXT,
};

import React from 'react';
import ReactDOM from 'react-dom';
import CanvasCard from '../canvas/canvas-card';

import { IconButton } from '@rmwc/icon-button';
import '@material/icon-button/dist/mdc.icon-button.css';

import './modal.css';

export default class RulesModal extends React.Component {
  constructor() {
    super();

    this.el = document.createElement('div');
  }

  get modalEl() {
    return document.getElementById('modal');
  }

  componentDidMount() {
    this.modalEl.appendChild(this.el);
  }

  componentWillUnmount() {
    this.modalEl.removeChild(this.el);
  }

  render() {
    const { onClose } = this.props;

    return ReactDOM.createPortal(
      <div className="content tall">
        <div className="column">
          <IconButton className="top-right-fab" icon="close" onClick={onClose} />

          <h2>Rules</h2>

          <p>
            Each card has four attributes: <strong>color</strong>, <strong>shape</strong>,{' '}
            <strong>fill</strong> and <strong>count</strong>.
          </p>

          <div className="explanation-wrapper">
            <div className="row">
              <h3>A match 🎉</h3>
              <p>
                These cards are a match because their color, shape and fills are all identical, and
                all of the counts are different.
              </p>
            </div>
            <div className="card">
              <CanvasCard card={{ d1: 1, d2: 1, d3: 1, d4: 1 }} width="100" height="150" />
              <ul>
                <li>
                  <span>Color:</span> yellow
                </li>
                <li>
                  <span>Shape:</span> circle
                </li>
                <li>
                  <span>Fill:</span> empty
                </li>
                <li>
                  <strong>Count:</strong> <strong>one</strong>
                </li>
              </ul>
            </div>
            <div className="card">
              <CanvasCard card={{ d1: 1, d2: 1, d3: 1, d4: 2 }} width="100" height="150" />
              <ul>
                <li>
                  <span>Color:</span> yellow
                </li>
                <li>
                  <span>Shape:</span> circle
                </li>
                <li>
                  <span>Fill:</span> empty
                </li>
                <li>
                  <strong>Count:</strong> <strong>two</strong>
                </li>
              </ul>
            </div>
            <div className="card">
              <CanvasCard card={{ d1: 1, d2: 1, d3: 1, d4: 3 }} width="100" height="150" />
              <ul>
                <li>
                  <span>Color:</span> yellow
                </li>
                <li>
                  <span>Shape:</span> circle
                </li>
                <li>
                  <span>Fill:</span> empty
                </li>
                <li>
                  <strong>Count:</strong> <strong>three</strong>
                </li>
              </ul>
            </div>
          </div>

          <div className="explanation-wrapper">
            <div className="row">
              <h3>Not a match 😭</h3>
              <p>
                These three cards are <strong>NOT</strong> a match because they don't have the same
                fills, and all three fills aren't represented. Notice how one is empty and two are
                filled? Yeah... that breaks the match.
              </p>
            </div>
            <div className="card">
              <CanvasCard card={{ d1: 2, d2: 2, d3: 1, d4: 1 }} width="100" height="150" />
            </div>
            <div className="card">
              <CanvasCard card={{ d1: 2, d2: 2, d3: 2, d4: 2 }} width="100" height="150" />
            </div>
            <div className="card">
              <CanvasCard card={{ d1: 2, d2: 2, d3: 2, d4: 3 }} width="100" height="150" />
            </div>
          </div>

          <div className="explanation-wrapper">
            <div className="row">
              <h3>A match 😍</h3>
              <p>
                The fills and counts are all different. The shapes and colors are identical. No attribute has exactly two representatives, so it's a match!
              </p>
            </div>
            <div className="card">
              <CanvasCard card={{ d1: 2, d2: 2, d3: 1, d4: 1 }} width="100" height="150" />
            </div>
            <div className="card">
              <CanvasCard card={{ d1: 2, d2: 2, d3: 2, d4: 2 }} width="100" height="150" />
            </div>
            <div className="card">
              <CanvasCard card={{ d1: 2, d2: 2, d3: 3, d4: 3 }} width="100" height="150" />
            </div>
          </div>

          <div className="explanation-wrapper">
            <div className="row">
              <h3>Not a match 😈</h3>
              <p>
                Notice how the last two cards are red? They would be a match if they were all red,
                or if they were all different colors. But representing just two of any of the
                attributes is what breaks a match.
              </p>
            </div>
            <div className="card">
              <CanvasCard card={{ d1: 1, d2: 1, d3: 1, d4: 1 }} width="100" height="150" />
            </div>
            <div className="card">
              <CanvasCard card={{ d1: 2, d2: 2, d3: 2, d4: 2 }} width="100" height="150" />
            </div>
            <div className="card">
              <CanvasCard card={{ d1: 2, d2: 3, d3: 3, d4: 3 }} width="100" height="150" />
            </div>
          </div>

          <div className="explanation-wrapper">
            <div className="row">
              <h3>A match 🙌🦄🍕</h3>
              <p>
                This is a match because all four attribues--color, shape, fill and count--are
                different. These matches can be tough to find.
              </p>
            </div>
            <div className="card">
              <CanvasCard card={{ d1: 1, d2: 1, d3: 1, d4: 1 }} width="100" height="150" />
            </div>
            <div className="card">
              <CanvasCard card={{ d1: 2, d2: 2, d3: 2, d4: 2 }} width="100" height="150" />
            </div>
            <div className="card">
              <CanvasCard card={{ d1: 3, d2: 3, d3: 3, d4: 3 }} width="100" height="150" />
            </div>
          </div>
        </div>
      </div>,
      this.el
    );
  }
}

import createStore from 'unistore';
import constants from '../constants';
import getRandomName from '../utilities/get-random-name';

// Actions
import setActiveMultiplayerGame from './actions/set-active-multiplayer-game';
import setAssets from './actions/set-assets';
import setCurrentUser from './actions/set-current-user';
import setGameMetadata from './actions/set-game-metadata';
import setGameMode from './actions/set-game-mode';
import setGame from './actions/set-game';
import setMatchGif from './actions/set-match-gif';
import setMultiplayerGame from './actions/set-multiplayer-game';
import setRandomName from './actions/set-random-name';
import setStrikeGif from './actions/set-strike-gif';

const LOCALSTORAGE_NAME = 'bunches-state';
const VERSION = 'cache-v0.0.0';

let localStorageState = getLocalStorage() || {};

if (localStorageState.VERSION != VERSION) {
  console.info('refreshing localStorage', VERSION);
  localStorageState = {};
}

const initialState = {
  VERSION,
  currentUser: null,
  game: null,
  gameMode: constants.GAME_MODES.NORMAL,
  matchGif: null,
  strikeGif: null,
  multiplayerGames: [],
  randomName: getRandomName(),
  ...localStorageState,
  // Not overridden by localStorageState
  activeMultiplayerGame: '',
  gameMetadata: { type: constants.GAME_TYPES.SOLO },
  assets: [],
};

const store = createStore(initialState);

const actions = store => ({
  setActiveMultiplayerGame,
  setAssets,
  setCurrentUser,
  setGameMetadata,
  setGameMode,
  setGame,
  setMatchGif,
  setMultiplayerGame,
  setRandomName,
  setStrikeGif,
});

setWindowState();
store.subscribe(setWindowState);

function setWindowState() {
  if (typeof window == 'object') {
    window.state = store.getState();
  }
}

store.subscribe(() => setLocalStorage(store.getState()));

function getLocalStorage() {
  let result = {};

  if (typeof window == 'object') {
    const stringified = localStorage.getItem(LOCALSTORAGE_NAME);

    if (stringified) {
      result = JSON.parse(stringified);
    }

    if (result.game && result.game.selectedIndexes) {
      result = {
        ...result,
        game: { ...result.game, selectedIndexes: new Set(result.game.selectedIndexes) },
      };
    }
  }

  return result;
}

function setLocalStorage(state) {
  if (state.game && state.game.selectedIndexes) {
    state = {
      ...state,
      game: { ...state.game, selectedIndexes: Array.from(state.game.selectedIndexes) },
    };
  }

  if (state.assets) {
    state = { ...state, assets: null };
  }

  localStorage &&
    state.currentUser &&
    localStorage.setItem(LOCALSTORAGE_NAME, JSON.stringify(state));
}

export { actions, store };

import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';

// Individual assets
import { LineImg } from './line';

export class Assets extends React.Component {
  constructor() {
    super();

    this.images = [
      { name: 'line-#E7E247', props: { stroke: '#E7E247' }, El: LineImg },
      { name: 'line-#881600', props: { stroke: '#881600' }, El: LineImg },
      { name: 'line-#1E2019', props: { stroke: '#1E2019' }, El: LineImg },
    ];
    this.resolves = [];
    this.promises = this.images.map(() => new Promise(resolve => this.resolves.push(resolve)));
  }

  get filteredImages() {
    const { filter } = this.props;

    return !filter ? this.images : this.images.filter(({ name }) => filter.includes(name));
  }

  get filteredPromises() {
    const { filter } = this.props;

    return !filter
      ? this.promises
      : this.promises.filter((promise, i) => filter.includes(this.images[i].name));
  }

  async componentDidMount() {
    const assets = await this.setAssets();

    this.props.onLoad && this.props.onLoad(assets);
  }

  async setAssets() {
    const { setAssets } = this.props;

    const assets = await Promise.all(this.promises);

    setAssets(assets);

    return assets;
  }

  render() {
    return (
      <div id="assets" style={{ display: 'none' }}>
        {this.filteredImages.map(({ name, props, El }, i) => (
          <El {...props} className={name} key={i} onLoad={e => this.resolves[i](e.target)} />
        ))}
      </div>
    );
  }
}

export default connect(
  '',
  actions
)(Assets);

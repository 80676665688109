/* globals window */
import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';
import getDefaultState from '../../game/get-default-state';
import omitEmptyValues from '../../utilities/omit-empty-values';
import constants from '../../constants';
import setGame from '../../datastore/actions/set-game';

class FirebaseSync extends React.Component {
  get rootRef() {
    return window.firebase.database().ref('games');
  }

  get gameRef() {
    const { activeMultiplayerGame } = this.props;

    if (!activeMultiplayerGame) {
      throw new Error('no activeMultiplayerGame found');
    }

    return this.rootRef.child(this.props.activeMultiplayerGame);
  }

  componentDidMount() {
    this.startDownstreamSync();
  }

  componentDidUpdate(prevProps) {
    const { activeMultiplayerGame, game } = this.props;

    if (prevProps.activeMultiplayerGame != activeMultiplayerGame) {
      this.stopDownstreamSync();

      activeMultiplayerGame && this.startDownstreamSync();
    }
  }

  startDownstreamSync() {
    this.__handleGameUpdate = this.gameRef.on('value', this.handleGameUpdate.bind(this));
  }

  stopDownstreamSync() {
    this.__handleGameUpdate && this.gameRef.off('value', this.__handleGameUpdate);
  }

  async handleGameUpdate(snapshot) {
    const { setGameMetadata, setMultiplayerGame } = this.props;
    const { game: dbGame, emails } = snapshot.val();
    const game = getDefaultState(dbGame);

    const activeEmails = await this.setActiveEmails(emails);

    setGameMetadata({ activeEmails, type: constants.GAME_TYPES.HEAD_TO_HEAD });

    setMultiplayerGame({ ...game, state: dbGame.state });
  }

  async setActiveEmails(existingEmails) {
    const { currentUser, randomName } = this.props;
    const activeEmails = new Set(existingEmails);
    const name = currentUser.email || randomName;

    if (!activeEmails.has(name)) {
      activeEmails.add(name);

      activeEmails.size && (await this.gameRef.child('emails').set(Array.from(activeEmails)));
    }

    return activeEmails;
  }

  render() {
    return null;
  }
}

export default connect(
  'activeMultiplayerGame,currentUser,game,randomName',
  actions
)(FirebaseSync);

import React from 'react';
import ReactDOM from 'react-dom';
import getMatchesByUidFlat from '../../utilities/get-matches-by-uid-flat';
import getStrikesByUidFlat from '../../utilities/get-strikes-by-uid-flat';
import CanvasCard from '../canvas/canvas-card';

import { Fab } from '@rmwc/fab';
import '@material/fab/dist/mdc.fab.css';

import { IconButton } from '@rmwc/icon-button';
import '@material/icon-button/dist/mdc.icon-button.css';

import './modal.css';

export default class ResultsModal extends React.Component {
  constructor() {
    super();

    this.el = document.createElement('div');
  }

  get modalEl() {
    return document.getElementById('modal');
  }

  componentDidMount() {
    this.modalEl.appendChild(this.el);
  }

  componentWillUnmount() {
    this.modalEl.removeChild(this.el);
  }

  calculateResults() {
    const { game } = this.props;
    const matchesFlat = getMatchesByUidFlat(game);
    const strikesFlat = getStrikesByUidFlat(game);
    const uids = matchesFlat
      .concat(strikesFlat)
      .reduce((uids, { uid }) => uids.add(uid), new Set());

    return Array.from(uids).reduce((results, uid) => {
      const { matches } = matchesFlat.find(match => match.uid == uid) || { matches: [] };
      const { strikes } = strikesFlat.find(strike => strike.uid == uid) || { strikes: [] };
      const metadata = matches
        .concat(strikes)
        .reduce((result, { metadata }) => ({ ...result, ...metadata }), {});
      const { email, randomName } = metadata;
      const strikeCount = strikes ? strikes.length : 0;
      const matchCount = matches ? matches.length : 0;
      const score = matchCount - strikeCount;

      results.push({ name: email || randomName, strikeCount, matchCount, score, matches });

      return results;
    }, []);
  }

  render() {
    const { message, onClick, onClose, onReplay } = this.props;
    const results = this.calculateResults();

    return ReactDOM.createPortal(
      <div className="content tall" onClick={onClick}>
        <div className="column">
          <h2>{message}</h2>
          {onClose && <IconButton className="top-right-fab" icon="close" onClick={onClose} />}
          {onReplay && <Fab icon="play_circle_outline" label="Replay" onClick={onReplay} />}
          <table className="results">
            <thead>
              <tr>
                <th>Email</th>
                <th>Matches</th>
                <th>Strikes</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {results.map(({ name, matchCount, strikeCount, score, matches }, i) => {
                return [
                  <tr key={`${i}-stats`}>
                    <td className="email">{name}</td>
                    <td>{matchCount}</td>
                    <td>{strikeCount}</td>
                    <td>
                      <strong>{score}</strong>
                    </td>
                  </tr>,
                  <tr key={`${i}-matches`}>
                    <td colSpan="4">
                      <UserMatches matches={matches} />
                    </td>
                  </tr>,
                ];
              })}
            </tbody>
          </table>
        </div>
      </div>,
      this.el
    );
  }
}

function UserMatches({ matches }) {
  return (
    <div className="user-matches">
      {matches.map(({ cards }, i) => (
        <div className="match" key={`match-${i}`}>
          {cards.map((card, i) => (
            <CanvasCard key={`card-${i}`} card={card} width="25" height="50" />
          ))}
        </div>
      ))}
    </div>
  );
}

import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';
import ResultsModal from '../modals/results-modal';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

class ResultsButton extends React.Component {
  constructor() {
    super();

    this.state = {
      resultsIsDisplayed: false,
    };
  }

  getResultsSetter(resultsIsDisplayed) {
    return () => {
      resultsIsDisplayed && gtag('event', 'results-click');

      this.setState({ resultsIsDisplayed });
    };
  }

  render() {
    const { game } = this.props;
    const { resultsIsDisplayed } = this.state;

    return resultsIsDisplayed ? (
      <ResultsModal game={game} message="Results" onClick={this.getResultsSetter(false)} />
    ) : (
      <Button onClick={this.getResultsSetter(true)}>Results</Button>
    );
  }
}

export default connect(
  'game',
  actions
)(ResultsButton);

import React from 'react';
import CanvasBackground from './canvas-background';
import CanvasForeground from './canvas-foreground';
import ReactResizeDetector from 'react-resize-detector';

import './canvas.css';

export default props => {
  return (
    <div id="canvas-container">
      <ReactResizeDetector handleWidth handleHeight>
        <CanvasForeground />
        <CanvasBackground />
      </ReactResizeDetector>
    </div>
  );
};

import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';
import renderForeground from './render/render-foreground';
import getGameBoardDimensions from './utilities/get-game-board-dimensions';
import Assets from '../assets';

export class CanvasForeground extends React.Component {
  constructor() {
    super();

    this.canvasRef = React.createRef();
  }

  get canvas() {
    return this.canvasRef.current;
  }

  get ctx() {
    return this.canvas.getContext('2d');
  }

  componentDidMount() {
    window.canvasForegroundCtx = this.ctx;

    this.renderCanvas();
  }

  componentDidUpdate() {
    this.renderCanvas();
  }

  handleClick(e) {
    const { game, setGame } = this.props;
    const { width, height } = this.canvas.getBoundingClientRect();
    const { rowCount, columnCount } = getGameBoardDimensions({
      cardCount: game.dealt.length,
      width,
      height,
    });
    const selectedIndexes = new Set(game.selectedIndexes);
    const columns = columnCount;
    const rows = rowCount;
    const { column, row } = this.getCoordinates(e, { columns, rows });
    const i = row * columns + column;
    const selected = selectedIndexes.has(i);

    selected ? selectedIndexes.delete(i) : selectedIndexes.add(i);

    setGame({ ...game, selectedIndexes });
  }

  getCoordinates(e, { columns, rows }) {
    const { left, top, width, height } = this.canvas.getBoundingClientRect();
    const { pageX: clickX, pageY: clickY } = e;
    const column = Math.floor((clickX - left) / (width / columns));
    const row = Math.floor((clickY - top) / (height / rows));

    return { column, row };
  }

  renderCanvas() {
    const { assets, currentUser } = this.props;

    assets.length && currentUser && renderForeground(this.ctx, this.props);
  }

  render() {
    const { height, width } = this.props;

    return (
      <div>
        <Assets filter={['line-#E7E247', 'line-#881600', 'line-#1E2019']} />
        <canvas
          id="canvas-foreground"
          height={height}
          width={width}
          ref={this.canvasRef}
          onClick={this.handleClick.bind(this)}
        />
      </div>
    );
  }
}

export default connect(
  'assets,currentUser,game',
  actions
)(CanvasForeground);

import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';
import constants from '../../constants';

class GameType extends React.Component {
  get gameType() {
    const { gameMetadata } = this.props;

    return constants.GAME_TYPES_TEXT[gameMetadata.type];
  }

  render() {
    return <h4>{this.gameType}</h4>;
  }
}

export default connect(
  'gameMetadata',
  actions
)(GameType);

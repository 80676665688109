/* globals window */
import omitEmptyValues from '../../utilities/omit-empty-values';

export default ({ activeMultiplayerGame, game }, updatedGame = null) => {
  const canEvaluate = game && updatedGame;
  const matchesChanged = canEvaluate && game.matches.length != updatedGame.matches.length;
  const strikesChanged = canEvaluate && game.strikes.length != updatedGame.strikes.length;
  const deckChanged = canEvaluate && game.deck.length != updatedGame.deck.length;
  const stateChanged = canEvaluate && game.state != updatedGame.state;
  const shouldUpdateDb = matchesChanged || strikesChanged || deckChanged || stateChanged;

  if (activeMultiplayerGame && shouldUpdateDb) {
    const ref = window.firebase
      .database()
      .ref('games')
      .child(activeMultiplayerGame);
    const dbGame = { ...updatedGame, selectedIndexes: null };

    ref.update(omitEmptyValues({ game: dbGame, updated: Date.now() }));

    return { game: dbGame, selectedIndexes: new Set() };
  } else {
    return { game: updatedGame };
  }
};

import React from 'react';
import { renderToString } from 'react-dom/server';

function LineSvg({ width, height, x1, x2, y1, y2, stroke, strokeWidth }) {
  const defaultStrokeWidth = 1.75;

  return (
    <svg
      width={width || defaultStrokeWidth * 2}
      height={height || defaultStrokeWidth * 2}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1={x1 || 0}
        x2={x2 || 200}
        y1={y1 || defaultStrokeWidth}
        y2={y2 || defaultStrokeWidth}
        stroke={stroke || 'orange'}
        strokeWidth={strokeWidth || defaultStrokeWidth}
      />
    </svg>
  );
}

export default function LineImg(props) {
  const svgString = renderToString(<LineSvg {...props} />);
  const svgBase64 = btoa(svgString);

  return (
    <img
      className={props.className}
      src={`data:image/svg+xml;base64,${svgBase64}`}
      onLoad={props.onLoad}
    />
  );
}

export { LineSvg, LineImg };

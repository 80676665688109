export default function getMatchesByUid(game) {
  return game.matches.reduce((matchesByUid, match) => {
    const uid = match.metadata.uid;

    if (!matchesByUid[uid]) {
      matchesByUid[uid] = [];
    }

    matchesByUid[uid].push(match);

    return matchesByUid;
  }, {});
}

import getGameBoardDimensions from '../utilities/get-game-board-dimensions';
import clear from '../utilities/clear';
import drawCard from '../utilities/draw-card';

export default (ctx, { width, height, assets, game }) => {
  if (!width || !height || !game.dealt.length || !assets) {
    return;
  }

  const { dealt, selectedIndexes } = game;
  const gameBoardDimensions = getGameBoardDimensions({
    cardCount: dealt.length,
    width,
    height,
  });
  const blockOrigins = getBlockOrigins(gameBoardDimensions);

  clear(ctx);

  selectedIndexes && drawSelections(ctx, { selectedIndexes, blockOrigins });
  drawCards(ctx, { dealt, blockOrigins });

  // console.table(blockOrigins);
};

function getBlockOrigins({ roundedWidth, roundedHeight, rowWidth, columnHeight }) {
  const blockOrigins = [];

  let y = roundedHeight;

  while ((y = y - columnHeight) > -1) {
    let x = roundedWidth;

    while ((x = x - rowWidth) > -1) {
      blockOrigins.unshift({ x, y, width: rowWidth, height: columnHeight });
    }
  }

  return blockOrigins;
}

function drawSelections(ctx, { selectedIndexes, blockOrigins }) {
  let i = blockOrigins.length;

  while (i--) {
    if (selectedIndexes.has(i)) {
      const { x, y, width, height } = blockOrigins[i];

      ctx.beginPath();

      ctx.fillStyle = '#f5f5f5';
      ctx.rect(x, y, width, height);
      ctx.fill();

      ctx.closePath();
    }
  }
}

function drawCards(ctx, { dealt, blockOrigins }) {
  let i = dealt.length;

  while (i--) {
    drawCard(ctx, dealt[i], blockOrigins[i]);
  }
}

module.exports = function getAllCardsSorted() {
  const allCards = [];
  const d1 = [1, 2, 3];
  const d2 = [1, 2, 3];
  const d3 = [1, 2, 3];
  const d4 = [1, 2, 3];

  let i = d1.length;
  while (i--) {
    let j = d2.length;
    while (j--) {
      let k = d3.length;
      while (k--) {
        let l = d4.length;
        while (l--) {
          allCards.push({ d1: d1[i], d2: d2[j], d3: d3[k], d4: d4[l] });
        }
      }
    }
  }

  return allCards;
};

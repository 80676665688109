/* globals window */
import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';

class Authentication extends React.Component {
  get auth() {
    return window.firebase.auth();
  }

  componentDidMount() {
    this.auth.onAuthStateChanged(this.props.setCurrentUser);
  }

  render() {
    return null;
  }
}

export default connect(
  '',
  actions
)(Authentication);

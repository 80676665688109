const isMatch = require('./is-match');

function findMatches(cardsToMatch, matches = []) {
  const unmatchedCards = [...cardsToMatch];
  let i = unmatchedCards.length;

  while (i--) {
    const card1 = unmatchedCards[i];
    let k = i;

    while (k--) {
      const card2 = unmatchedCards[k];
      let j = k;

      while (j--) {
        const card3 = unmatchedCards[j];
        const set = [card1, card2, card3];

        if (isMatch(set)) {
          matches.push(set);
          break;
        }
      }
    }
  }

  return matches;
}

module.exports = findMatches;

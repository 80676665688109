/* global functions */
import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';
import GiphyModal from '../modals/giphy-modal';
import isMatch from '../../game/is-match';
import makeMatch from '../../game/make-match';
import makeStrike from '../../game/make-strike';
import dealCards from '../../game/deal-cards';
import findMatches from '../../game/find-matches';
import startNewGame from './start-new-game';
import startNewMultiplayerGame from './start-new-multiplayer-game';
import ResultsModal from '../modals/results-modal';
import HeadToHeadPendingModal from '../modals/head-to-head-pending-modal';
import StartGameModal from '../modals/start-game-modal';
import constants from '../../constants';
import getRandomArrayElement from '../../utilities/get-random-array-element';

const MODAL_MAX_MILLIS = 10 * 1000;

class GameManagement extends React.Component {
  constructor() {
    super();

    this.state = {
      matchIsDisplayed: false,
      strikeIsDisplayed: false,
      embedUrl: '',
    };
  }

  get getGif() {
    return window.firebase.functions().httpsCallable('getGif');
  }

  get matchesCount() {
    return findMatches(this.props.game.dealt).length;
  }

  get deckCount() {
    return this.props.game.deck.length;
  }

  get dealtCount() {
    return this.props.game.dealt.length;
  }

  componentDidMount() {
    this.manageGame();

    const { matchGif, strikeGif } = this.props;

    !matchGif && this.updateMatchGif();
    !strikeGif && this.updateStrikeGif();
  }

  componentDidUpdate() {
    this.manageGame();
  }

  manageGame() {
    const { currentUser, game, randomName, setGame } = this.props;

    if (game) {
      if (game.selectedIndexes && game.selectedIndexes.size == 3) {
        this.evaluateSet(currentUser, game, randomName);
      } else if (this.dealtCount < 9 || !this.matchesCount) {
        this.deal();
      }

      if (!this.deckCount && !this.matchesCount && game.state != constants.GAME_STATES.FINISHED) {
        setGame({ ...game, state: constants.GAME_STATES.FINISHED });

        gtag('event', 'game-over');
      }
    }
  }

  clearGame() {
    this.props.setGame();
  }

  evaluateSet({ email, uid }, game, randomName) {
    const { setGame } = this.props;
    const cards = [];
    let updatedGame;

    // Safari demonstrates a bug where some undefined cards sneak in. Checking for them.
    game.selectedIndexes.forEach(i => game.dealt[i] && cards.push(game.dealt[i]));

    if (cards.length == 3 && isMatch(cards)) {
      updatedGame = makeMatch(game, cards, { uid, email, randomName });

      this.showMatch();
      setGame(updatedGame);
    } else {
      updatedGame = makeStrike(game, { uid, email, randomName });

      this.showStrike();
      setGame(updatedGame);
    }
  }

  deal() {
    this.deckCount > 0 && this.props.setGame(dealCards(this.props.game, 3));
  }

  async showMatch() {
    this.__showMatchTimer && clearTimeout(this.__showMatchTimer);

    this.setState({ matchIsDisplayed: true });

    this.__showMatchTimer = setTimeout(this.closeModal.bind(this), MODAL_MAX_MILLIS);
  }

  async showStrike() {
    this.__showStrikeTimer && clearTimeout(this.__showStrikeTimer);

    this.setState({ strikeIsDisplayed: true });

    this.__showStrikeTimer = setTimeout(this.closeModal.bind(this), MODAL_MAX_MILLIS);
  }

  async updateMatchGif() {
    const word = getRandomArrayElement(['party', 'celebration', 'fantastic']);
    const embedUrl = await this.embedUrl(word);

    this.props.setMatchGif(embedUrl);
  }

  async updateStrikeGif() {
    const word = getRandomArrayElement(['fail', 'fall', 'hurt']);
    const embedUrl = await this.embedUrl(word);

    this.props.setStrikeGif(embedUrl);
  }

  async embedUrl(tag) {
    const gif = await this.getGif({ tag });
    const embedUrl = gif.data.data.embed_url;

    return embedUrl;
  }

  async closeModal() {
    const { matchIsDisplayed, strikeIsDisplayed } = this.state;

    if (matchIsDisplayed) {
      this.updateMatchGif();
    }

    if (strikeIsDisplayed) {
      this.updateStrikeGif();
    }

    this.setState({ matchIsDisplayed: false, strikeIsDisplayed: false });
  }

  startMultiplayerGame() {
    const { game, setGame } = this.props;

    setGame({ ...game, state: constants.GAME_STATES.PLAYING });
  }

  handleReplay() {
    const { currentUser, gameMode, setActiveMutiplayerGame, setGame } = this.props;

    startNewMultiplayerGame({
      currentUser,
      gameMode,
      setActiveMutiplayerGame,
      setGame,
    });
  }

  startGame({ gameMode, type }) {
    const { currentUser, setActiveMultiplayerGame, setGame, setGameMetadata } = this.props;

    if (type == constants.GAME_TYPES.SOLO) {
      startNewGame({ gameMode, setGame, setGameMetadata });
    } else if (type == constants.GAME_TYPES.HEAD_TO_HEAD) {
      startNewMultiplayerGame({
        currentUser,
        gameMode,
        setActiveMultiplayerGame,
      });
    }
  }

  render() {
    const { matchIsDisplayed, strikeIsDisplayed } = this.state;
    const { game, gameMetadata, matchGif, strikeGif } = this.props;
    const embedUrl = (matchIsDisplayed && matchGif) || (strikeIsDisplayed && strikeGif);
    const message = (matchIsDisplayed && "It's a match!") || (strikeIsDisplayed && 'Boooo');
    let result = null;

    if (embedUrl) {
      result = (
        <GiphyModal
          src={embedUrl}
          message={message}
          onClick={this.closeModal.bind(this)}
          onClose={this.closeModal.bind(this)}
        />
      );
    } else if (!game) {
      result = <StartGameModal onStartGame={this.startGame.bind(this)} />;
    } else if (game.state == constants.GAME_STATES.FINISHED) {
      result = (
        <ResultsModal
          game={game}
          message="Game Over"
          onClick={this.clearGame.bind(this)}
          onReplay={
            gameMetadata.type == constants.GAME_TYPES.HEAD_TO_HEAD && this.handleReplay.bind(this)
          }
        />
      );
    } else if (game.state == constants.GAME_STATES.PENDING) {
      result = <HeadToHeadPendingModal onPlay={this.startMultiplayerGame.bind(this)} />;
    }

    return result;
  }
}

export default connect(
  'currentUser,game,gameMetadata,gameMode,matchGif,strikeGif,randomName',
  actions
)(GameManagement);

/* globals window */
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';
import constants from '../../constants';

import { Fab } from '@rmwc/fab';
import '@material/fab/dist/mdc.fab.css';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { IconButton } from '@rmwc/icon-button';
import '@material/icon-button/dist/mdc.icon-button.css';

import './modal.css';

export class StartGameModal extends React.Component {
  constructor() {
    super();

    this.el = document.createElement('div');
  }

  get modalEl() {
    return document.getElementById('modal');
  }

  componentDidMount() {
    this.modalEl.appendChild(this.el);
  }

  componentWillUnmount() {
    this.modalEl.removeChild(this.el);
  }

  render() {
    const { onStartGame } = this.props;

    return ReactDOM.createPortal(
      <div className="content">
        <div className="column">
          <h2>Start Game</h2>
          <div className="start-game-wrapper">
            <Fab
              icon="play_circle_outline"
              label="Solo / Easy"
              onClick={() =>
                onStartGame({
                  gameMode: constants.GAME_MODES.EASY,
                  type: constants.GAME_TYPES.SOLO,
                })
              }
            />
            <Fab
              icon="play_circle_outline"
              label="Solo / Hard"
              onClick={() =>
                onStartGame({
                  gameMode: constants.GAME_MODES.NORMAL,
                  type: constants.GAME_TYPES.SOLO,
                })
              }
            />
          </div>
          <div className="start-game-wrapper">
            <Fab
              icon="play_circle_outline"
              label="Multi / Easy"
              onClick={() =>
                onStartGame({
                  gameMode: constants.GAME_MODES.EASY,
                  type: constants.GAME_TYPES.HEAD_TO_HEAD,
                })
              }
            />
            <Fab
              icon="play_circle_outline"
              label="Multi / Hard"
              onClick={() =>
                onStartGame({
                  gameMode: constants.GAME_MODES.NORMAL,
                  type: constants.GAME_TYPES.HEAD_TO_HEAD,
                })
              }
            />
          </div>
        </div>
      </div>,
      this.el
    );
  }
}

export default connect(
  '',
  actions
)(StartGameModal);

export default function getStrikesByUid(game) {
  return game.strikes.reduce((strikesByUid, strike) => {
    const uid = strike.metadata.uid;

    if (!strikesByUid[uid]) {
      strikesByUid[uid] = [];
    }

    strikesByUid[uid].push(strike);

    return strikesByUid;
  }, {});
}

module.exports = function isMatch(cards) {
  if (cards.length != 3) {
    throw new Error(`cards.length is ${cards.length} but must equal 3`);
  }

  // byDimension:  { d1: Set { 1, 2 }, d2: Set { 1 }, d3: Set { 1 }, d4: Set { 1 } }
  const byDimension = cards.reduce((result, card) => {
    for (dimension in card) {
      if (!result[dimension]) {
        result[dimension] = new Set();
      }

      result[dimension].add(card[dimension]);
    }

    return result;
  }, {});

  // byDimensionFlat: [ Set { 1, 2 }, Set { 1 }, Set { 1 }, Set { 1 } ]
  const byDimensionFlat = Object.keys(byDimension).map(key => byDimension[key]);

  // sizes: Set { 2, 1 }
  const sizes = new Set(byDimensionFlat.map(d => d.size));

  return !sizes.has(2);
};

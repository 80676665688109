import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';
import findMatches from '../../game/find-matches';
import findCardIndex from '../../utilities/find-card-index';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

class FindMatchesButton extends React.Component {
  findMatches() {
    const { game, setGame } = this.props;
    const matches = this.getMatches();
    const indexGroups = matches.map(match => match.map(card => findCardIndex(game.dealt, card)));
    const firstGroup = indexGroups[0];
    const selectedIndexes = new Set(firstGroup);

    setGame({ ...game, selectedIndexes });
  }

  getMatches() {
    return findMatches(this.props.game.dealt);
  }

  render() {
    return <Button onClick={this.findMatches.bind(this)}>Find Matches</Button>;
  }
}

export default connect(
  'game',
  actions
)(FindMatchesButton);

import React from 'react';
import ReactDOM from 'react-dom';

import { IconButton } from '@rmwc/icon-button';
import '@material/icon-button/dist/mdc.icon-button.css';

import './modal.css';

export default class GiphyModal extends React.Component {
  constructor() {
    super();

    this.el = document.createElement('div');
  }

  get modalEl() {
    return document.getElementById('modal');
  }

  componentDidMount() {
    this.modalEl.appendChild(this.el);
  }

  componentWillUnmount() {
    this.modalEl.removeChild(this.el);
  }

  render() {
    const { src, message, onClick, onClose } = this.props;

    return ReactDOM.createPortal(
      <div className="content tall" onClick={onClick}>
        <div className="column">
          <IconButton className="top-right-fab" icon="close" onClick={onClose} />

          <h2>{message}</h2>
          <iframe
            src={src}
            width="480"
            height="460"
            frameBorder="0"
            className="giphy-embed"
            allowFullScreen
          />
          <p>
            <a href={src}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/quiver-bunches.appspot.com/o/assets%2FPoweredby_100px_Badge.gif?alt=media&token=50c18f46-52c7-460a-99e3-70205ee0a75b"
                alt="powered by giphy"
              />
            </a>
          </p>
        </div>
      </div>,
      this.el
    );
  }
}

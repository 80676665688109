import clear from '../utilities/clear';
import drawCard from '../utilities/draw-card';

export default (ctx, card) => {
  if (!card) {
    return;
  }

  clear(ctx);

  drawCard(ctx, card, { x: 0, y: 0, width: ctx.canvas.width, height: ctx.canvas.height });
};

/* globals window */
import React from 'react';
import { Button } from '@rmwc/button';

import '@material/button/dist/mdc.button.min.css';

import './page.css';

export default class LandingPage extends React.Component {
  get firebase() {
    return window.firebase;
  }

  get auth() {
    return window.firebase.auth();
  }

  handleGoogleLoginClick() {
    this.logInWithGoogle();
  }

  logInWithGoogle() {
    const provider = new this.firebase.auth.GoogleAuthProvider();

    provider.addScope('email');

    this.auth.signInWithPopup(provider);
  }
  
  handleAnonymousLoginClick() {
    this.signInAnonymously();
  }

  signInAnonymously() {
    this.auth.signInAnonymously();
  }

  render() {
    return (
      <div id="landing-page">
        <h1>Bunches</h1>

        <Button raised onClick={this.handleGoogleLoginClick.bind(this)}>
          Log in with Google
        </Button>

        <Button raised onClick={this.handleAnonymousLoginClick.bind(this)}>
          Guest Mode
        </Button>

        <p>A pattern matching game to play alone or with friends</p>
      </div>
    );
  }
}

/* globals window */
import constants from '../../constants';
import getInitialState from '../../game/get-initial-state';
import omitEmptyValues from '../../utilities/omit-empty-values';
import dealUntilMatch from '../../game/deal-until-match';

export default ({ currentUser, gameMode, setActiveMultiplayerGame }) => {
  const key = currentUser.uid;

  gtag('event', `start-new-multiplayer-game-${gameMode}`);

  history.pushState({}, '', `/${key}`);
  window.dispatchEvent(new Event('popstate'));

  setActiveMultiplayerGame(key);

  const ref = window.firebase
    .database()
    .ref('games')
    .child(key);
  const game = dealUntilMatch(getInitialState({ mode: gameMode }));

  const dbGame = {
    ...game,
    selectedIndexes: null,
    state: constants.GAME_STATES.PENDING,
  };

  return ref.set(omitEmptyValues({ game: dbGame, created: Date.now() }));
};

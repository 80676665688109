import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';
import findMatches from '../../game/find-matches';

import getMatchesByUidFlat from '../../utilities/get-matches-by-uid-flat';
import getStrikesByUidFlat from '../../utilities/get-strikes-by-uid-flat';

export class ControlBar extends React.Component {
  get emailOrRandomName() {
    const {
      currentUser: { email },
      randomName,
    } = this.props;

    return !!email && email != 'null' ? email : randomName;
  }

  get myMatches() {
    const { currentUser, game } = this.props;
    let matches = [];

    if (currentUser) {
      const flatMatches = getMatchesByUidFlat(game);
      let foundMatchesItem = flatMatches.find(({ uid }) => uid == currentUser.uid) || {};

      matches = foundMatchesItem.matches || [];
    }

    return matches;
  }

  get myStrikes() {
    const { currentUser, game } = this.props;
    let strikes = [];

    if (currentUser) {
      const flatStrikes = getStrikesByUidFlat(game);
      const foundStrikesItems = flatStrikes.find(({ uid }) => uid == currentUser.uid) || {};

      strikes = foundStrikesItems.strikes || [];
    }

    return strikes;
  }

  get matchCount() {
    const matches = this.getMatches();

    return matches.length;
  }

  get deckCount() {
    return this.props.game.deck.length;
  }

  getMatches() {
    return findMatches(this.props.game.dealt);
  }

  getResultsSetter(resultsIsDisplayed) {
    return () => this.setState({ resultsIsDisplayed });
  }

  render() {
    const { randomName } = this.props;

    return (
      <div id="control-bar">
        <div className="flex" />
        <span>
          <strong>{randomName}</strong>
        </span>
        <span>Deck: {leftPad(this.deckCount)}</span>
        <span>Strikes: {leftPad(this.myStrikes.length)}</span>
        <span>Matches: {leftPad(this.myMatches.length)}</span>
        <span>Possible: {leftPad(this.matchCount)}</span>
      </div>
    );
  }
}

export default connect(
  'currentUser,game,randomName',
  actions
)(ControlBar);

function leftPad(value) {
  return value < 10 ? <span className="left-pad">{value}</span> : <span>{value}</span>;
}

import React from 'react';
import renderBackground from "./render/render-background";

export default class CanvasBackground extends React.Component {
  constructor() {
    super();

    this.canvasRef = React.createRef();
  }

  get canvas() {
    return this.canvasRef.current;
  }

  get ctx() {
    return this.canvas.getContext('2d');
  }

  componentDidMount() {
    window.canvasBackgroundCtx = this.ctx;

    this.renderCanvas();
  }

  componentDidUpdate() {
    this.renderCanvas();
  }

  renderCanvas() {
    renderBackground(this.ctx, this.props);
  }

  render() {
    const { height, width } = this.props;

    return <canvas id="canvas-background" height={height} width={width} ref={this.canvasRef} />;
  }
}

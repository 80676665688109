/* globals window */
import React from 'react';
import { Provider, connect } from 'unistore/react';
import { actions, store } from '../datastore';
import Authentication from './authentication/authentication';
import LandingPage from './pages/landing-page';
import GamePage from './pages/game-page';
import './app.css';

export default props => {
  return (
    <Provider store={store}>
      <ConnectedWrapper />
    </Provider>
  );
};

function Wrapper({ currentUser, setActiveMultiplayerGame }) {
  return (
    <div>
      <Authentication />
      <Routing
        onPopstate={() => {
          const { pathname } = window.location;
          const parts = pathname.slice(1).split('/');
          const [key] = parts;

          setActiveMultiplayerGame(key);
        }}
      />
      <div id="app">{currentUser ? <GamePage /> : <LandingPage />}</div>
      <div id="modal" />
      <div id="notification" />
    </div>
  );
}

const ConnectedWrapper = connect(
  'currentUser',
  actions
)(Wrapper);

class Routing extends React.Component {
  constructor({ onPopstate }) {
    super();

    this.__handlePopstate = onPopstate;
  }
  componentDidMount() {
    this.__handlePopstate();

    window.addEventListener('popstate', this.__handlePopstate);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.__handlePopstate);
  }

  render() {
    return null;
  }
}

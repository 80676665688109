const isMatch = require('./is-match');
const findCardIndex = require('../utilities/find-card-index');

module.exports = function makeMatch(state, cards, metadata = {}) {
  if (!isMatch(cards)) {
    throw new Error('Cards are not a match');
  }

  if (cards.length != 3) {
    throw new Error(`Must match 3 cards. ${cards.length} cards submitted`);
  }

  const dealt = [...state.dealt];

  const cardIndexes = cards.map(card => findCardIndex(dealt, card));

  if (cardIndexes.includes(-1)) {
    throw new Error(`card not found`);
  }

  const sortedCardIndexes = cardIndexes.sort((a, b) => a > b ? 1 : -1);
  let i = sortedCardIndexes.length;

  while (i--) {
    dealt.splice(sortedCardIndexes[i], 1);
  }

  const matches = [...state.matches];
  
  matches.push({ cards, metadata });

  return {
    ...state,
    dealt,
    matches,
    selectedIndexes: new Set(),
  };
};

// https://github.com/Daplie/knuth-shuffle

module.exports = function shuffle(array) {
  let i = array.length;
  let temporaryValue;
  let randomIndex;

  while (i--) {
    randomIndex = Math.floor(Math.random() * i);

    // And swap it with the current element.
    temporaryValue = array[i];
    array[i] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';
import renderCard from './render/render-card';
import Assets from '../assets';

export class CanvasForeground extends React.Component {
  constructor() {
    super();

    this.canvasRef = React.createRef();
  }

  get canvas() {
    return this.canvasRef.current;
  }

  get ctx() {
    return this.canvas.getContext('2d');
  }

  componentDidMount() {
    this.renderCanvas();
  }

  componentDidUpdate() {
    this.renderCanvas();
  }

  renderCanvas() {
    const { assets, card } = this.props;

    assets.length && renderCard(this.ctx, card);
  }

  render() {
    const { height, width } = this.props;

    return (
      <div>
        <Assets filter={['line-#E7E247', 'line-#881600', 'line-#1E2019']} />
        <canvas className="canvas-card" height={height} width={width} ref={this.canvasRef} />
      </div>
    );
  }
}

export default connect(
  'assets',
  actions
)(CanvasForeground);

export default function getGameBoardDimensions({ cardCount, width, height }) {
  const columnCount = getColumnCount({ cardCount, width, height });
  const rowCount = cardCount / columnCount;
  const roundedWidth = width - (width % columnCount);
  const roundedHeight = height - (height % rowCount);
  const rowWidth = roundedWidth / columnCount;
  const columnHeight = roundedHeight / rowCount;

  return {
    rowCount,
    columnCount,
    roundedWidth,
    roundedHeight,
    rowWidth,
    columnHeight,
    width,
    height,
  };
}

function getColumnCount({ cardCount, width, height }) {
  let columnCount = 3;

  if (width > height) {
    switch (cardCount) {
      case 6:
        columnCount = 3;
        break;

      case 9:
        columnCount = 3;
        break;

      case 12:
        columnCount = 4;
        break;

      case 15:
        columnCount = 5;
        break;

      default:
        break;
    }
  }

  return columnCount;
}

import React from 'react';
import ReactDOM from 'react-dom';

import './modal.css';

export default class ResultsModal extends React.Component {
  constructor() {
    super();

    this.el = document.createElement('div');

    this.state = {
      hidden: true,
    };
  }

  get modalEl() {
    return document.getElementById('notification');
  }

  componentDidMount() {
    const { onClose } = this.props;
    const timeout = this.props.timeout || 2000;

    this.modalEl.appendChild(this.el);

    setTimeout(() => this.setState({ hidden: false }), 0);

    setTimeout(() => this.setState({ hidden: true }), timeout - 500);

    setTimeout(onClose, timeout);
  }

  componentWillUnmount() {
    this.modalEl.removeChild(this.el);
  }

  render() {
    const { text, onClick } = this.props;
    const { hidden } = this.state;

    return ReactDOM.createPortal(
      <div className="content" hidden={hidden} onClick={onClick}>
        <div>{text}</div>
      </div>,
      this.el
    );
  }
}

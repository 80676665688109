import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';
import CanvasWrapper from '../canvas/canvas-wrapper';
import TopBar from '../control/top-bar';
import ControlBar from '../control/control-bar';
import GameManagement from '../game-management/game-management';
import FirebaseSync from '../game-management/firebase-sync';

import './page.css';

class GamePage extends React.Component {
  render() {
    const { activeMultiplayerGame, game } = this.props;

    return (
      <div id="game-page">
        <GameManagement />
        {activeMultiplayerGame && <FirebaseSync />}
        {game && <TopBar />}
        {game && <CanvasWrapper />}
        {game && <ControlBar />}
      </div>
    );
  }
}

export default connect(
  'activeMultiplayerGame,currentUser,game',
  actions
)(GamePage);

import constants from '../../constants';
import getInitialState from '../../game/get-initial-state';

export default ({ gameMode, setGame, setGameMetadata }) => {
  const game = getInitialState({ mode: gameMode });

  gtag('event', `start-new-game-${gameMode}`);

  history.pushState(game, '', '/');
  window.dispatchEvent(new Event('popstate'));

  setGameMetadata({ type: constants.GAME_TYPES.SOLO });

  return setGame(game);
};

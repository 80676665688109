import getMatchesByUid from './get-matches-by-uid';

export default function getMatchesByUidFlat(game) {
  const matchesByUid = getMatchesByUid(game);

  return Object.keys(matchesByUid).map(uid => {
    const matches = matchesByUid[uid];
    const metadata = matches[0] && matches[0].metadata;

    return { uid, matches, metadata };
  });
}

import findMatches from './find-matches';
import dealCards from './deal-cards';

module.exports = game => {
  let newGame = { ...game };

  while (!findMatches(newGame.dealt).length) {
    newGame = dealCards(newGame, 3);
  }

  return newGame;
};

import React from 'react';
import ReactDOM from 'react-dom';

import { IconButton } from '@rmwc/icon-button';
import '@material/icon-button/dist/mdc.icon-button.css';

import './modal.css';

export default class AboutModal extends React.Component {
  constructor() {
    super();

    this.el = document.createElement('div');
  }

  get modalEl() {
    return document.getElementById('modal');
  }

  componentDidMount() {
    this.modalEl.appendChild(this.el);
  }

  componentWillUnmount() {
    this.modalEl.removeChild(this.el);
  }

  render() {
    const { onClose } = this.props;

    return ReactDOM.createPortal(
      <div className="content tall">
        <div className="column">
          <IconButton className="top-right-fab" icon="close" onClick={onClose} />

          <h2>About</h2>

          <div className="paragraph">
            <h3>Greetings!</h3>

            <p>
              My name is{' '}
              <a href="https://chrisesplin.com" target="_blank">
                Chris Esplin
              </a>
              , and I love building stuff like this game.
            </p>

            <img
              src="https://firebasestorage.googleapis.com/v0/b/quiver-bunches.appspot.com/o/assets%2Fsnowy-headshot-square.jpg?alt=media&token=f99adf7f-d21a-4977-91d3-d5695afd3017"
              alt="chris is covered in snow"
            />

            <p>
              The family business is{' '}
              <a href="https://www.calligraphy.org" target="_blank">
                Calligraphy.org
              </a>
              . I may have a full-time programming job, or I may be contracting, skiing, biking or
              just being a dad.
            </p>

            <p>
              Check out the Bunches code at the{' '}
              <a href="https://gitlab.com/deltaepsilon/bunches" target="_blank">
                GitLab repo
              </a>
              .
            </p>

            <p>
              Let me know if you find any bugs by filing a{' '}
              <a href="https://gitlab.com/deltaepsilon/bunches/issues" target="_blank">
                GitLab issue
              </a>{' '}
              or by sending an email to{' '}
              <a href="mailto:chris@chrisesplin.com">chris@chrisesplin.com</a>.
            </p>
          </div>
        </div>
      </div>,
      this.el
    );
  }
}

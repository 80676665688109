/* globals window */
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';
import NotificationModal from './notification-modal';
import copyToClipboard from '../../utilities/copy-to-clipboard';
import constants from '../../constants';

import { Fab } from '@rmwc/fab';
import '@material/fab/dist/mdc.fab.css';

import { Button, ButtonIcon } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { IconButton } from '@rmwc/icon-button';
import '@material/icon-button/dist/mdc.icon-button.css';

import './modal.css';

export class HeadToHeadPendingModal extends React.Component {
  constructor() {
    super();

    this.el = document.createElement('div');

    this.state = {
      notificationText: '',
    };
  }

  get modalEl() {
    return document.getElementById('modal');
  }

  get multiplayerGame() {
    const { activeMultiplayerGame, multiplayerGames } = this.props;
    const { value } = multiplayerGames.find(({ key }) => key == activeMultiplayerGame) || {};

    return value;
  }

  componentDidMount() {
    this.modalEl.appendChild(this.el);
  }

  componentWillUnmount() {
    this.modalEl.removeChild(this.el);
  }

  handleGameCopy() {
    const { activeMultiplayerGame } = this.props;
    const link = `${window.location.origin}/${activeMultiplayerGame}`;

    copyToClipboard(link);

    this.setState({ notificationText: 'copied game link to clipboard' });
  }

  render() {
    const { gameMetadata, onPlay } = this.props;
    const { notificationText } = this.state;
    const activeEmails = Array.from(gameMetadata.activeEmails || []);

    return ReactDOM.createPortal(
      <div className="content">
        {notificationText && (
          <NotificationModal
            text={notificationText}
            onClose={() => this.setState({ notificationText: '' })}
          />
        )}
        <div className="column">
          <h2>Head to Head</h2>
          <h3>Players</h3>
          <ul className="emails">
            {activeEmails.map(email => (
              <li key={email}>{email}</li>
            ))}
          </ul>
          <div className="play-fab-wrapper">
            <Button style={{ width: '100%' }} raised onClick={this.handleGameCopy.bind(this)}>
              <ButtonIcon icon="share" /> Invite{' '}
            </Button>
            <Fab icon="play_circle_outline" label="Start Game" onClick={onPlay} />
          </div>
          <p>Click "Play" when your invitees are ready. Head starts aren't fair!</p>
        </div>
      </div>,
      this.el
    );
  }
}

export default connect(
  'activeMultiplayerGame,gameMetadata',
  actions
)(HeadToHeadPendingModal);

const getDefaultState = require('./get-default-state');
const getAllCardsSorted = require('../utilities/get-all-cards-sorted');
const shuffle = require('../utilities/shuffle');
const constants = require('../constants');

module.exports = function getInitialState({ mode } = { mode: constants.GAME_MODES.NORMAL }) {
  let allCardsSorted = getAllCardsSorted();

  if (mode == constants.GAME_MODES.EASY) {
    allCardsSorted = allCardsSorted.filter(({ d3 }) => d3 == 2);
  }

  return {
    ...getDefaultState(),
    deck: shuffle(allCardsSorted),
    mode,
  };
};

import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';
import constants from '../../constants';
import AboutModal from '../modals/about-modal';
import RulesModal from '../modals/rules-modal';
import EndGameButton from './end-game-button';
import ResultsButton from './results-button';
import FindMatchesButton from './find-matches-button';

import GameMode from './game-mode';
import GameType from './game-type';

import { Menu, MenuItem, MenuSurfaceAnchor } from '@rmwc/menu';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/list/dist/mdc.list.css';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/icon.css';

import { IconButton } from '@rmwc/icon-button';
import '@material/icon-button/dist/mdc.icon-button.css';

import './control.css';

export class TopBar extends React.Component {
  constructor() {
    super();

    this.state = {
      menuIsOpen: false,
      aboutModalIsOpen: false,
      rulesModalIsOpen: false,
    };
  }

  get auth() {
    return window.firebase.auth();
  }

  handleSelection(e) {
    const index = e.detail.index;
    let i = 0;

    switch (index) {
      case i++:
        this.toggleRulesModal();
        break;

      case i++:
        this.toggleAboutModal();
        break;

      case i++:
        this.auth.signOut();
        localStorage.clear();
        this.props.setRandomName();
        break;

      default:
        break;
    }
  }

  toggleAboutModal() {
    const { aboutModalIsOpen } = this.state;

    !aboutModalIsOpen && gtag('event', 'about-modal-open');

    this.setState({ aboutModalIsOpen: !aboutModalIsOpen });
  }

  toggleRulesModal() {
    const { rulesModalIsOpen } = this.state;

    !rulesModalIsOpen && gtag('event', 'rules-modal-open');

    this.setState({ rulesModalIsOpen: !rulesModalIsOpen });
  }

  render() {
    const { gameMetadata } = this.props;
    const { menuIsOpen, aboutModalIsOpen, rulesModalIsOpen } = this.state;
    const changeGameModeIsDisabled = gameMetadata.type != constants.GAME_TYPES.SOLO;

    return (
      <div id="top-bar">
        <div className="buttons flex">
          <GameType />
          <span className="hide-landscape">/</span>
          <GameMode />
          <ResultsButton />
          <EndGameButton />
          {/* <FindMatchesButton /> */}
          <div className="flex" />
        </div>
        <div className="menu-wrapper">
          <MenuSurfaceAnchor>
            <Menu
              open={menuIsOpen}
              onSelect={this.handleSelection.bind(this)}
              onClose={evt => this.setState({ menuIsOpen: false })}
            >
              <MenuItem>Rules</MenuItem>
              <MenuItem>About</MenuItem>
              <MenuItem>
                Sign Out{' '}
                <Icon style={{ color: 'var(--mdc-theme-secondary-dark)' }} icon="power_off" />
              </MenuItem>
            </Menu>

            <IconButton icon="menu" onClick={evt => this.setState({ menuIsOpen: !menuIsOpen })} />
          </MenuSurfaceAnchor>
        </div>
        {rulesModalIsOpen && <RulesModal onClose={this.toggleRulesModal.bind(this)} />}
        {aboutModalIsOpen && <AboutModal onClose={this.toggleAboutModal.bind(this)} />}
      </div>
    );
  }
}

export default connect(
  'gameMetadata,gameMode',
  actions
)(TopBar);

module.exports = function dealCards(state, count = 3) {
  const deck = [...state.deck];
  const cardsToDeal = deck.splice(0, count);

  if (cardsToDeal.length != count) {
    throw new Error(
      `Not enough cards to deal. Only ${state.deck.length} cards when ${count} were requested`
    );
  }

  return {
    ...state,
    deck,
    dealt: state.dealt.concat(cardsToDeal),
  };
};
